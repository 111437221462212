import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'etapa',
    standalone: true
})
export class EtapaPipe implements PipeTransform {

  transform(value: string, fecha: any = '', ...args: unknown[]): unknown {
    if(value){
      return `${this.genString(value, fecha)}`;
    } else {
      return null;
    }
  }

  genString(value: string, fecha: any = ''): String{
    let etapa: string = '';
    switch(value){
      case 'DONT_START':
        etapa = `MES 0: No comenzó`;
        break;
      case 'RESET':
        etapa = `MES ${this.calculaMeses(fecha)}: Reseteo`;
        break;
      case 'STABILIZATION':
        etapa = `MES ${this.calculaMeses(fecha)}: Estabilización`;
        break;
      case 'GROWTH':
        etapa = `MES ${this.calculaMeses(fecha)}: Algo pasa`;
        break;
      case 'MAINTENANCE':
        etapa = `MES ${this.calculaMeses(fecha)}: Mantenimiento`;
        break;
    }
    return etapa;
  }

  calculaMeses(fecha){
    const fInicio: any = moment(new Date(fecha).toISOString().split('T')[0]);
    const fActual: any = moment(new Date().toISOString().split('T')[0]); 
    let diferencia = fActual.diff(fInicio, 'months');
    return diferencia;
  }

}
